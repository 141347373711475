import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { auth } from "../src/utils/firebase";
import withAuth from "../src/utils/withAuth";
import router from "next/router";
import Head from 'next/head';
import Link from "../src/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Better Than Vegas
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  staticError: {
    fontSize: "0.75rem",
    color: "red",
  },
  animatedError: {
    animation: `$shake 500ms ${theme.transitions.easing.easeInOut}`,
    fontSize: "0.75rem",
    color: "red",
  },
  "@keyframes shake": {
    "10%, 90%": {
      transform: "translate3d(-1px, 0, 0)",
    },
    "20%, 80%": {
      transform: "translate3d(2px, 0, 0)",
    },
    "30%, 50%, 70%": {
      transform: "translate3d(-4px, 0, 0)"
    },
    "40%, 60%": {
      transform: "translate3d(4px, 0, 0)"
    }
  },
}));

function Login(props) {
  if (props.auth.authenticated) {
    router.push("/");
  }

  const classes = useStyles();

  const [animErrors, setAnimErrors] = useState(false);
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: ""
  });

  const submitLoginForm = () => {
    if (loginForm.email.length === 0) {
      let newState = Object.assign({}, loginForm);
      newState.emailError = "Email can not be blank";
      newState.passwordError = "";
      setLoginForm(newState);
      return;
    }
    if (loginForm.email.match(/([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})/i) === null) {
      let newState = Object.assign({}, loginForm);
      newState.emailError = "Email is not an email";
      newState.passwordError = "";
      setLoginForm(newState);
      return;
    }
    if (loginForm.password.length === 0) {
      let newState = Object.assign({}, loginForm);
      newState.emailError = "";
      newState.passwordError = "Password can not be blank";
      setLoginForm(newState);
      return;
    }

    auth
      .signInWithEmailAndPassword(loginForm.email, loginForm.password)
      .then(() => {
        router.push("/");
      })
      .catch(function(error) {
        if (error.code === "auth/invalid-email" ||
            error.code === "auth/user-disabled" ||
            error.code === "auth/user-not-found") {
          let newState = Object.assign({}, loginForm);
          newState.emailError = error.message;
          newState.passwordError = "";
          setLoginForm(newState);
        }
        else if (error.code === "auth/wrong-password" || error.code === "auth/too-many-requests") {
          let newState = Object.assign({}, loginForm);
          newState.emailError = "";
          newState.passwordError = error.message;
          setLoginForm(newState);
        }
        else {
          console.error("Error with email and password login: ", error);
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Head>
        <title>Sign In - Better than Vegas</title>
      </Head>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>

          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            required
            id="email"
            label="Email Address"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
            onChange={e => {
              let newState = Object.assign({}, loginForm);
              newState.emailError = null;
              newState.email = (e.target.value).trim();
              setLoginForm(newState);
            }}
            value={loginForm.email}
            helperText={loginForm.emailError && <div onAnimationEnd={() => setAnimErrors(false)} className={animErrors ? classes.animatedError : classes.staticError}>{loginForm.emailError}</div>}
          />

          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            required
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => {
              let newState = Object.assign({}, loginForm);
              newState.passwordError = null;
              newState.password = (e.target.value).trim();
              setLoginForm(newState);
            }}
            value={loginForm.password}
            helperText={loginForm.passwordError && <div onAnimationEnd={() => setAnimErrors(false)} className={animErrors ? classes.animatedError : classes.staticError}>{loginForm.passwordError}</div>}
          />

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={e => {
              e.preventDefault();
              setAnimErrors(true);
              submitLoginForm();
              console.log("submitted login form");
            }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default withAuth(Login);
